import React from "react";
import sanityClient from "../../client";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import imageUrlBuilder from "@sanity/image-url";

// Get a pre-configured url-builder from your sanity client
const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

export default function Image(props) {
  const image = props.image;
  const classs = props.class;

  return (
    <>
      {image && (
        <>
          {image.hotspot ? (
            <LazyLoadImage
              loading="lazy"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              src={urlFor(image.asset)}
              key={image.asset._ref}
              alt={image.alt}
              className={classs}
              placeholdersrc={urlFor(image.asset).height(2).url()}
              style={{
                objectPosition: `${image.hotspot.x * 100}% ${
                  image.hotspot.y * 100
                }%`,
              }}
              effect="opacity"
            />
          ) : (
            <LazyLoadImage
              loading="lazy"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              src={urlFor(image.asset)}
              key={image.asset._ref}
              alt={image.alt}
              className={classs}
              placeholdersrc={urlFor(image.asset).height(2).url()}
              effect="opacity"
            />
          )}
        </>
      )}
    </>
  );
}
